@import '../../scss/2.colors';

.Carousel {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width:1200px){
        gap: 20vmax;
    }

    &_btn {
        display: flex;
        justify-content: space-between;
        min-width: 80%;

        &_arrow {
            position: relative;
            z-index: 3;
            cursor: pointer;

            &:hover {
                color: getColor(color_three);
            }
        }


    }
}