// RESET
:root{
    font-size: 62.5%;
}

*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}