.Layout{
    margin: 0 auto;
    max-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
 }
 
 // MEDIA QUERIES
 @media (max-width:767px){
    .Layout{
       margin-top: 0;
       padding-top: 0vmax;
       max-width: 100vw;
    }
 }