.Navigation {
    position: fixed;
    top: 0;
    right: 0;
    width: 10vw;
    height: 100vh;
    z-index: 100;

    @media (max-width:1200px){
        width: 100%;
    }
}