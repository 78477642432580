@import '../../../scss/2.colors';

.Modal{
    position: fixed;
    z-index: 50;
    background-color: getColor(color_modal);
    width: 70%;
    border: 1px solid getColor(color_nav);
    box-shadow: 1px 1px 1px getColor(color_nav);
    padding: 16px;
    left: 15%;
    top: 20%;
}

// MEDIA QUERIES
@media (max-width: 600px) {

}