@import '../../scss/2.colors';
@import '../../scss/4.fontSize';

.Footer{
    min-height: calc(2rem + 2.8vmax);;
    background-color: getColor(color_footer_background);
    font-size: getFontSize(xxs);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: getColor(color_nav);

    &_Title{
        font-size: getFontSize(xxs);
    }

    &_Links{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        place-items: center;

        list-style-type: none;

        & > li > a{
            text-decoration: none;
            color: getColor(color_nav);
            font-weight: bold;
        }
    }
}

// MEDIA QUERIES
@media (max-width:767px){
    .Footer{
        grid-template-columns: 1fr 2fr;
        &_Links{
            place-items: center start;
        }
    }
}