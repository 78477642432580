@import '../../scss/2.colors';
@import '../../scss/4.fontSize';

.logo{
    max-width: 2vmax;
}

.PageTemplate {
    min-height: 100vh;
    width: 100vw;
    background-color: getColor(color_background);
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
        width: 100%;
        height: calc(2rem + 2.8vmax);
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: getColor(color_footer_background);
        color: getColor(color_white);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        font-size: getFontSize(xl);
    }

    &_box {
        padding: 2vmax 0;
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        font-size: getFontSize(s) !important;
        background-color: getColor(color_white);
    }
}

.about_section {
    margin-top: 1vmax;
    padding: 1vmax;
    display: flex;
    flex-direction: column;
    row-gap: 5vmax;
}
  
.about_img {
    max-width: 50%;
    border-radius: 1vmax;

    @media (max-width:1200px){
        width: 100%;
        max-width: 100%;
    }
}
  
.section{
    display: flex;
    gap: 2vmax;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 2px solid getColor(color_black);
    padding-bottom: 4vmax;

    @media (max-width:1200px){
        flex-direction: column-reverse;
        width: 100%;
    }

    &_details{
        display: flex;
        flex-direction: column;
        row-gap: 3vmax;
        justify-content: center;
        align-items: center;
    }
}