@import './scss/1.reset';
@import './scss/2.colors';
@import './scss/4.fontSize';
@import './scss/6.animations';
@import './scss/7.main_titles';

body {
  font-family: 'Kanit', sans-serif;
  // font-family: 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: getFontSize(xxs);
  background-color: getColor(color_body_background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.route-wrapper {
  position: relative;
}

.route-wrapper>div {
  position: absolute;
  width: 100%;
}

.table {
  font-size: getFontSize(xxs);
}